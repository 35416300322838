import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const SearchIcon = ({ fill }) => (
  <Icon
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.36207 9.35273L13 13M10.8763 5.85457C10.8763 8.53567 8.66542 10.7091 5.93815 10.7091C3.21089 10.7091 1 8.53567 1 5.85457C1 3.17346 3.21089 1 5.93815 1C8.66542 1 10.8763 3.17346 10.8763 5.85457Z"
      stroke={fill}
      strokeWidth="0.75"
    />
  </Icon>
);
