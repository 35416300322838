import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useFlexSearch } from "react-use-flexsearch";
import { navigate } from "gatsby";

// Icons
import { SearchIcon } from "../icons/search-icon";

const SearchContainer = styled.div`
  width: 100%;
  max-width: 350px;

  & button {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    margin: 0 0 0 auto;
    padding: 16px 0;
  }

  @media (max-width: 768px) {
    max-width: 100%;

    & button {
      margin: 0;
      padding: 0;
      align-items: center;

      order: 1;

      letter-spacing: 0.05em;
    }
  }
`;

const SearchForm = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  width: 100%;

  & input {
    padding: 0;
    margin: 0 20px 0 0;
    height: 29px;

    border: 0;

    width: calc(100% - 100px);
    border-bottom: 1px solid ${props => props.color};
    color: ${props => props.color};

    background-color: transparent;

    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.05em;

    &:focus {
      outline: none;
    }
  }

  & button[type="submit"] {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    width: fit-content;
  }

  @media (max-width: 768px) {
    max-width: 100%;

    & input {
      order: 12;

      border-bottom: 1px solid #163262;
      color: #163262;

      height: auto;
      line-height: 20px;
    }

    & button,
    & button[type="submit"] {
      margin: 0;
      padding: 0;

      order: 1;
      align-items: baseline;

      letter-spacing: 0.05em;
    }
  }
`;

export const Search = ({ index, store, location, text }) => {
  const [isSearchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState("");
  const results = useFlexSearch(query, index, store);

  const navigateToSearchResultsPage = event => {
    event.preventDefault();
    setSearchOpen(false);
    navigate(`/search/?q=${query}`, {
      state: {
        searchResults: results,
        searchQuery: query,
      },
    });
  };

  useEffect(() => {
    if (typeof window !== undefined && isSearchOpen === true) {
      document.getElementById("search-input").focus();
    }
  }, [isSearchOpen]);

  useEffect(() => {
    setSearchOpen(false);
    setQuery("");
  }, [location]);

  return (
    <SearchContainer color={text}>
      {isSearchOpen ? (
        <SearchForm onSubmit={navigateToSearchResultsPage} color={text}>
          <input
            id="search-input"
            name="query"
            type="text"
            value={query}
            onChange={e => setQuery(e.target.value)}
          />

          <button type="submit">
            <SearchIcon fill={text} />
            <span>Search</span>
          </button>
        </SearchForm>
      ) : (
        <button onClick={() => setSearchOpen(true)}>
          <SearchIcon fill={text} />
          <span>Search</span>
        </button>
      )}
    </SearchContainer>
  );
};
