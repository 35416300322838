import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import { Header } from "./header";
import { Footer } from "./footer";

// Icons
import { VerticalLogo } from "../icons/vertical-logo";

const Main = styled.main`
  flex: 1;

  padding: 0 40px;
  margin: 120px 0 0 0;

  @media (max-width: 768px) {
    margin: 105px 0 0 0;
    padding: 0 20px;
  }

  & .mobile-header-fill {
    display: none;
    position: fixed;
    background-color: ${props => props.backgroundColor};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 0;

    @media (max-width: 1074px) {
      display: block;
      height: 48px;
    }

    @media (max-width: 768px) {
      height: 51px;
    }
  }
`;

export const App = ({
  location,
  isDesktop,
  children,
  text,
  background,
  hideSplash,
  setHideSplash,
}) => {
  useEffect(() => {
    if (location !== `/` && hideSplash === false) {
      setHideSplash(true);
    }
  }, [location]);

  return (
    <>
      <Header
        location={location}
        text={text}
        background={background}
        isDesktop={isDesktop}
      />

      <Main backgroundColor={background}>
        {children}
        <div className="mobile-header-fill" />
      </Main>

      {isDesktop && (
        <Footer
          location={location}
          text={text}
          background={background}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop && <VerticalLogo fill={text} />}
    </>
  );
};
