import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Icon = styled.svg`
  display: block;

  position: fixed;
  top: 80px;
  right: 25px;
`;

export const VerticalLogo = ({ fill }) => (
  <Link to={`/`}>
    <Icon
      width="19"
      height="362"
      viewBox="0 0 19 362"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8 15.648L17.8 10.92L17.272 10.92L17.272 12.24C17.272 12.768 17.032 13.08 16.648 13.08C16.504 13.08 16.336 13.032 16.144 12.936L8.10401 8.71201L16.048 4.29601C16.24 4.17601 16.456 4.12801 16.624 4.12801C17.008 4.12801 17.272 4.41601 17.272 4.96801L17.272 6.00001L17.8 6.00001L17.8 0.312012L17.272 0.312012L17.272 0.624012C17.272 1.36801 16.96 1.75201 16.48 2.01601L7.19201 7.27201L2.87201 7.27201C1.93601 7.27201 1.50401 6.86401 1.50401 5.95201L1.50401 4.89601L1.00001 4.89601L1.00001 11.544L1.50401 11.544L1.50401 10.488C1.50401 9.57601 1.93601 9.14401 2.87201 9.14401L7.55201 9.14401L16.192 13.752C16.84 14.088 17.272 14.424 17.272 15.336L17.272 15.648L17.8 15.648Z"
        fill={fill}
      />
      <path
        d="M17.8 27.2889L17.8 21.3129L17.272 21.3129L17.272 22.0329C17.272 22.9449 16.864 23.3529 15.952 23.3529L2.87201 23.3529C1.93601 23.3529 1.50401 22.9449 1.50401 22.0329L1.50401 21.3129L1.00001 21.3129L1.00001 27.2889L1.50401 27.2889L1.50401 26.5689C1.50401 25.6569 1.93601 25.2249 2.87201 25.2249L15.952 25.2249C16.864 25.2249 17.272 25.6569 17.272 26.5689L17.272 27.2889L17.8 27.2889Z"
        fill={fill}
      />
      <path
        d="M17.8 49.9676L17.8 44.6876L17.272 44.6876L17.272 46.0076C17.272 46.8236 16.888 47.2076 16.072 47.2076L4.168 47.2076L17.8 36.9356L17.8 33.4076L17.272 33.4076L17.272 34.0316C17.272 34.6556 16.984 35.1116 16.6 35.3516L16.432 35.4476L2.75201 35.4476C1.91201 35.4476 1.50401 35.0876 1.50401 34.2476L1.50401 33.4076L1.00001 33.4076L1.00001 38.6636L1.50401 38.6636L1.50401 37.3436C1.50401 36.5276 1.91201 36.1196 2.75201 36.1196L15.688 36.1196L1.00001 47.1596L1.00001 47.8796L16.072 47.8796C16.888 47.8796 17.272 48.2876 17.272 49.1276L17.272 49.9676L17.8 49.9676Z"
        fill={fill}
      />
      <path
        d="M17.8 56.0848L17.272 56.0848L17.272 56.9248C17.272 57.7408 16.888 58.1248 16.072 58.1248L2.75201 58.1248C1.91201 58.1248 1.504 57.7408 1.504 56.9248L1.504 56.0848L1.00001 56.0848L1 62.2768L1.504 62.2768L1.504 61.2208C1.504 60.3808 1.91201 59.9968 2.75201 59.9968L9.20801 59.9968L9.83201 60.6928L5.056 64.7488C3.688 65.9248 2.176 67.2448 1 68.3248L1 71.2288L1.456 71.2288C1.48 70.6288 2.10401 69.9328 3.04001 69.1168L10.936 61.9408L16.6 68.3728C17.08 68.9008 17.272 69.8128 17.272 70.7728L17.8 70.7728L17.8 65.1088L17.272 65.1088L17.272 66.3568C17.272 66.8368 17.056 67.1488 16.768 67.1488C16.576 67.1488 16.36 67.0288 16.144 66.7888L10.048 59.9968L16.072 59.9968C16.888 59.9968 17.272 60.3808 17.272 61.2208L17.272 62.0608L17.8 62.0608L17.8 56.0848Z"
        fill={fill}
      />
      <path
        d="M2.464 91.3903L17.8 85.1743L17.8 84.4783L2.56 78.4783C2.104 78.2863 1.528 77.9743 1.528 76.9183L1.528 76.4623L1 76.4623L1 81.4303L1.504 81.4303L1.504 80.4463C1.504 79.7023 1.816 79.2703 2.392 79.2703C2.584 79.2703 2.824 79.3423 3.088 79.4383L6.424 80.7103L6.424 87.8143L3.088 89.1103C2.824 89.2303 2.56 89.2783 2.368 89.2783C1.816 89.2783 1.504 88.8943 1.504 88.0783L1.504 87.1183L1 87.1183L1 93.3103L1.528 93.3103L1.528 92.8543C1.528 92.0863 1.864 91.6303 2.464 91.3903ZM7.048 80.9503L15.496 84.2383L7.048 87.5503L7.048 80.9503Z"
        fill={fill}
      />
      <path
        d="M13.24 118.831L13.312 119.407L17.68 118.879C17.584 118.471 17.536 118.111 17.536 117.799C17.536 116.623 18.208 115.711 18.208 113.935C18.208 111.607 17.032 108.775 13.576 108.775C10.288 108.775 9.376 111.343 8.848 113.239L8.32 115.063C7.936 116.479 7.288 118.615 4.792 118.615C2.896 118.615 1.288 117.319 1.288 114.751C1.288 112.399 2.656 110.191 6.64 109.159L6.544 108.559L1.312 109.159C1.456 109.639 1.504 110.071 1.504 110.359C1.504 111.631 0.568004 112.639 0.568003 114.775C0.568003 117.775 2.392 120.103 5.488 120.103C8.752 120.103 9.544 117.511 10.024 115.807L10.624 113.719C11.104 112.063 11.824 110.311 14.104 110.311C16.168 110.311 17.488 111.751 17.488 114.031C17.488 116.143 16.336 118.159 13.24 118.831Z"
        fill={fill}
      />
      <path
        d="M17.8 143.392L17.8 137.392L17.272 137.392L17.272 138.112C17.272 139.024 16.864 139.456 15.952 139.456L9.856 139.456L9.856 130.408L15.952 130.408C16.864 130.408 17.272 130.84 17.272 131.752L17.272 132.472L17.8 132.472L17.8 126.496L17.272 126.496L17.272 127.216C17.272 128.128 16.864 128.536 15.952 128.536L2.872 128.536C1.936 128.536 1.504 128.128 1.504 127.216L1.504 126.496L1 126.496L1 132.472L1.504 132.472L1.504 131.752C1.504 130.84 1.936 130.408 2.872 130.408L9.232 130.408L9.232 139.456L2.872 139.456C1.936 139.456 1.504 139.024 1.504 138.112L1.504 137.392L1 137.392L1 143.392L1.504 143.392L1.504 142.672C1.504 141.76 1.936 141.328 2.872 141.328L15.952 141.328C16.864 141.328 17.272 141.76 17.272 142.672L17.272 143.392L17.8 143.392Z"
        fill={fill}
      />
      <path
        d="M18.208 157.888C18.208 152.608 13.792 149.8 9.4 149.8C4.96 149.8 0.568002 152.632 0.568002 157.888C0.568001 163.168 4.984 165.976 9.376 165.976C13.816 165.976 18.208 163.144 18.208 157.888ZM9.376 151.888C14.296 151.888 17.488 154.384 17.488 157.912C17.488 161.488 14.224 163.888 9.4 163.888C4.48 163.888 1.288 161.416 1.288 157.864C1.288 154.288 4.552 151.888 9.376 151.888Z"
        fill={fill}
      />
      <path
        d="M17.8 189.2L17.8 183.92L17.272 183.92L17.272 185.24C17.272 186.056 16.888 186.44 16.072 186.44L4.168 186.44L17.8 176.168L17.8 172.64L17.272 172.64L17.272 173.264C17.272 173.888 16.984 174.344 16.6 174.584L16.432 174.68L2.752 174.68C1.912 174.68 1.504 174.32 1.504 173.48L1.504 172.64L1 172.64L1 177.896L1.504 177.896L1.504 176.576C1.504 175.76 1.912 175.352 2.752 175.352L15.688 175.352L0.999999 186.392L0.999999 187.112L16.072 187.112C16.888 187.112 17.272 187.52 17.272 188.36L17.272 189.2L17.8 189.2Z"
        fill={fill}
      />
      <path
        d="M17.8 201.294L17.8 195.318L17.272 195.318L17.272 196.038C17.272 196.95 16.864 197.358 15.952 197.358L2.872 197.358C1.936 197.358 1.504 196.95 1.504 196.038L1.504 195.318L0.999999 195.318L0.999999 201.294L1.504 201.294L1.504 200.574C1.504 199.662 1.936 199.23 2.872 199.23L15.952 199.23C16.864 199.23 17.272 199.662 17.272 200.574L17.272 201.294L17.8 201.294Z"
        fill={fill}
      />
      <path
        d="M17.8 214.204L17.8 207.172L17.272 207.172L17.272 207.892C17.272 208.804 16.864 209.212 15.952 209.212L2.872 209.212C1.936 209.212 1.504 208.804 1.504 207.892L1.504 207.172L0.999999 207.172L0.999998 214.588C0.999998 218.164 3.352 220.06 5.608 220.06C7.6 220.06 9.544 218.596 9.784 215.476C10.168 217.828 11.776 219.268 13.768 219.268C15.976 219.268 17.8 217.54 17.8 214.204ZM2.8 211.084L9.328 211.084L9.328 214.18C9.328 216.676 7.552 218.092 5.488 218.092C3.592 218.092 1.624 216.868 1.624 214.252L1.624 212.236C1.624 211.468 2.008 211.084 2.8 211.084ZM9.952 211.084L17.176 211.084L17.176 213.532C17.176 216.076 15.568 217.42 13.672 217.42C11.704 217.42 9.952 216.052 9.952 213.34L9.952 211.084Z"
        fill={fill}
      />
      <path
        d="M2.464 239.829L17.8 233.613L17.8 232.917L2.56 226.917C2.104 226.725 1.528 226.413 1.528 225.357L1.528 224.901L0.999998 224.901L0.999998 229.869L1.504 229.869L1.504 228.885C1.504 228.141 1.816 227.709 2.392 227.709C2.584 227.709 2.824 227.781 3.088 227.877L6.424 229.149L6.424 236.253L3.088 237.549C2.824 237.669 2.56 237.717 2.368 237.717C1.816 237.717 1.504 237.333 1.504 236.517L1.504 235.557L0.999997 235.557L0.999997 241.749L1.528 241.749L1.528 241.293C1.528 240.525 1.864 240.069 2.464 239.829ZM7.048 229.389L15.496 232.677L7.048 235.989L7.048 229.389Z"
        fill={fill}
      />
      <path
        d="M17.8 253.865L17.8 247.265L17.272 247.265L17.272 247.985C17.272 248.897 16.864 249.305 15.952 249.305L2.872 249.305C1.936 249.305 1.504 248.897 1.504 247.985L1.504 247.265L0.999997 247.265L0.999997 253.241L1.504 253.241L1.504 252.521C1.504 251.609 1.936 251.177 2.872 251.177L9.328 251.177L9.328 251.705C9.328 253.481 8.104 254.849 5.776 256.097L0.999996 258.617L0.999996 261.473L1.504 261.473C1.528 260.609 1.912 260.249 2.56 259.913L5.608 258.329C8.392 256.865 9.064 255.569 9.472 253.937C9.568 257.057 11.392 259.241 13.768 259.241C15.88 259.241 17.8 257.537 17.8 253.865ZM9.928 251.177L17.176 251.177L17.176 253.337C17.176 255.977 15.52 257.249 13.624 257.249C11.608 257.249 9.928 255.785 9.928 253.049L9.928 251.177Z"
        fill={fill}
      />
      <path
        d="M17.8 277.454L17.8 267.182L17.272 267.182L17.272 267.902C17.272 268.814 16.864 269.222 15.952 269.222L2.872 269.222C1.936 269.222 1.504 268.814 1.504 267.902L1.504 267.182L0.999996 267.182L0.999995 278.03C0.999995 278.798 0.855996 279.47 0.735995 279.926L5.82399 280.502L5.968 279.878C2.704 279.158 1.624 277.55 1.624 275.366L1.624 272.798C1.624 271.382 2.27199 271.094 3.06399 271.094L9.16 271.094L9.16 274.502C9.16 276.182 8.152 276.806 6.688 276.806L6.688 277.454L12.256 277.454L12.256 276.806C10.816 276.806 9.784 276.182 9.784 274.502L9.784 271.094L17.176 271.094L17.176 275.054C17.176 277.046 16.216 278.774 13.408 279.35L13.528 279.902L18.064 279.398C17.896 278.846 17.8 278.342 17.8 277.454Z"
        fill={fill}
      />
      <path
        d="M7.92999 303.865L7.99999 304.201L10.8 303.879C10.674 303.431 10.66 303.165 10.66 303.053C10.66 302.465 11.038 301.961 11.038 300.743C11.038 297.495 8.36399 295.927 5.81599 295.927C2.45599 295.927 0.747994 298.237 0.747994 300.533C0.747994 302.171 1.61599 303.781 3.32399 304.579L3.45 304.355C2.106 303.487 1.44799 302.269 1.44799 300.939C1.44799 298.951 3.02999 297.103 6.124 297.103C8.35 297.103 10.618 298.251 10.618 300.589C10.618 302.017 9.74999 303.375 7.92999 303.865Z"
        fill={fill}
      />
      <path
        d="M10.8 312.324L10.8 308.222L10.492 308.222L10.492 308.642C10.492 309.174 10.254 309.412 9.72199 309.412L2.092 309.412C1.546 309.412 1.29399 309.174 1.29399 308.642L1.29399 308.222L0.999994 308.222L0.999994 312.548C0.999994 314.634 2.37199 315.74 3.68799 315.74C4.84999 315.74 5.98399 314.886 6.12399 313.066C6.34799 314.438 7.28599 315.278 8.44799 315.278C9.73599 315.278 10.8 314.27 10.8 312.324ZM2.04999 310.504L5.85799 310.504L5.85799 312.31C5.85799 313.766 4.82199 314.592 3.61799 314.592C2.51199 314.592 1.36399 313.878 1.36399 312.352L1.36399 311.176C1.36399 310.728 1.58799 310.504 2.04999 310.504ZM6.22199 310.504L10.436 310.504L10.436 311.932C10.436 313.416 9.49799 314.2 8.39199 314.2C7.24399 314.2 6.22199 313.402 6.22199 311.82L6.22199 310.504Z"
        fill={fill}
      />
      <path
        d="M10.8 325.6L10.8 319.608L10.492 319.608L10.492 320.028C10.492 320.56 10.254 320.798 9.72199 320.798L2.09199 320.798C1.54599 320.798 1.29399 320.56 1.29399 320.028L1.29399 319.608L0.999994 319.608L0.999993 325.936C0.999993 326.384 0.915994 326.776 0.845994 327.042L3.81399 327.378L3.89799 327.014C1.99399 326.594 1.36399 325.656 1.36399 324.382L1.36399 322.884C1.36399 322.058 1.74199 321.89 2.20399 321.89L5.75999 321.89L5.75999 323.878C5.75999 324.858 5.17199 325.222 4.31799 325.222L4.31799 325.6L7.56599 325.6L7.56599 325.222C6.72599 325.222 6.12399 324.858 6.12399 323.878L6.12399 321.89L10.436 321.89L10.436 324.2C10.436 325.362 9.87599 326.37 8.23799 326.706L8.30799 327.028L10.954 326.734C10.856 326.412 10.8 326.118 10.8 325.6Z"
        fill={fill}
      />
      <path
        d="M10.8 343.718L10.8 339.868L10.492 339.868L10.492 340.288C10.492 340.82 10.254 341.058 9.72199 341.058L2.09199 341.058C1.54599 341.058 1.29399 340.82 1.29399 340.288L1.29399 339.868L0.999993 339.868L0.999993 343.354L1.29399 343.354L1.29399 342.934C1.29399 342.402 1.54599 342.15 2.09199 342.15L5.85799 342.15L5.85799 342.458C5.85799 343.494 5.14399 344.292 3.78599 345.02L0.999992 346.49L0.999992 348.156L1.29399 348.156C1.30799 347.652 1.53199 347.442 1.90999 347.246L3.68799 346.322C5.31199 345.468 5.70399 344.712 5.94199 343.76C5.99799 345.58 7.06199 346.854 8.44799 346.854C9.67999 346.854 10.8 345.86 10.8 343.718ZM6.20799 342.15L10.436 342.15L10.436 343.41C10.436 344.95 9.46999 345.692 8.36399 345.692C7.18799 345.692 6.20799 344.838 6.20799 343.242L6.20799 342.15Z"
        fill={fill}
      />
      <path
        d="M1.85399 359.985L10.8 356.359L10.8 355.953L1.90999 352.453C1.64399 352.341 1.30799 352.159 1.30799 351.543L1.30799 351.277L0.999992 351.277L0.999992 354.175L1.29399 354.175L1.29399 353.601C1.29399 353.167 1.47599 352.915 1.81199 352.915C1.92399 352.915 2.06399 352.957 2.21799 353.013L4.16399 353.755L4.16399 357.899L2.21799 358.655C2.06399 358.725 1.90999 358.753 1.79799 358.753C1.47599 358.753 1.29399 358.529 1.29399 358.053L1.29399 357.493L0.999992 357.493L0.999992 361.105L1.30799 361.105L1.30799 360.839C1.30799 360.391 1.50399 360.125 1.85399 359.985ZM4.52799 353.895L9.45599 355.813L4.52799 357.745L4.52799 353.895Z"
        fill={fill}
      />
    </Icon>
  </Link>
);
