import React, { useState } from "react";
import styled from "styled-components";

// Components
import SearchIndex from "../search/search-index";

const DesktopMenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 40px;

  padding: 0 25px 0 40px;

  font-size: 18px;
  line-height: 45px;
  letter-spacing: 0.05em;

  & nav {
    grid-column: 2 / 9;

    @media (max-width: 1075px) {
      grid-column: 1 / 9;
    }

    & ol {
      display: flex;
      flex-direction: row;

      & li {
        margin: 0 30px 0 0;

        & a {
          &:hover,
          &.current {
            color: ${props => props.current};
          }
        }
      }
    }
  }

  & .search-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    // align-items: center;

    // height: 23px;
    // padding: 8px 0 0 0;

    grid-column: 10 / 13;

    & svg {
      margin: 0 15px 0 0;
    }

    & button {
      font-size: 14px;
      letter-spacing: 0.05em;

      // height: 21px;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;

    font-size: 16px;
    line-height: 50px;
    letter-spacing: 0.05em;

    display: block;
    grid-template-columns: unset;
    grid-column-gap: unset;

    & nav {
      grid-column: unset;

      & ol {
        justify-content: space-between;

        & li {
          margin: 0;
        }
      }
    }
  }

  @media (max-width: 500px) {
    font-size: 13px;
  }
`;

export const DesktopMenu = ({ menu, text, location }) => {
  return (
    <DesktopMenuContainer current={text === `#163262` ? `#4383EF` : `#163262`}>
      <nav>
        <ol>{menu}</ol>
      </nav>
      <div className="search-container">
        <SearchIndex type={"desktop"} text={text} location={location} />
      </div>
    </DesktopMenuContainer>
  );
};
