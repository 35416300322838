import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";
import kebabCase from "lodash.kebabcase";

// Components
import { DesktopMenu } from "../navigation/desktop-menu";
import { MobileMenu } from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  z-index: 200;

  will-change: background-color, color;
  // transition: 150ms background-color ease, 150ms color ease;
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};

  @media (max-width: 1074px) {
    background-color: transparent;
  }
`;

export const Header = ({ location, text, background, isDesktop }) => {
  const [bgColor, setBgColor] = useState(`transparent`);

  useEffect(() => {
    setBgColor(`transparent`);

    const headerBg = setTimeout(() => {
      setBgColor(background);
    }, 250);
    return () => clearTimeout(headerBg);
  }, [background]);

  const data = useStaticQuery(graphql`
    {
      prismicMenus {
        data {
          header {
            page {
              document {
                ... on PrismicBibliography {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicBiography {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicContact {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicHomepage {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicNews {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicPress {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
                ... on PrismicExhibitions {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const menu = data.prismicMenus.data.header.map((page, index) => {
    if (page.page.document.url === `/`) {
      return (
        <li key={`header_menu_link_${index}_${page.page.document.id}`}>
          <Link
            to={page.page.document.url}
            activeClassName="current"
            className={location.includes(`/artwork/`) ? `current` : ``}
            title={page.page.document.data.title.text}
          >
            {page.page.document.data.title.text}
          </Link>
        </li>
      );
    } else if (page.page.document.url === `/biography/`) {
      return (
        <li key={`header_menu_link_${index}_${page.page.document.id}`}>
          <Link
            to={page.page.document.url}
            activeClassName="current"
            className={
              location.includes(`/biography/`) ||
              location.includes(`/cv/`) ||
              location.includes(`/bibliography/`)
                ? `current`
                : ``
            }
            title={page.page.document.data.title.text}
          >
            {page.page.document.data.title.text}
          </Link>
        </li>
      );
    } else {
      return (
        <li key={`header_menu_link_${index}_${page.page.document.id}`}>
          <Link
            to={page.page.document.url}
            activeClassName="current"
            partiallyActive
            title={page.page.document.data.title.text}
          >
            {page.page.document.data.title.text}
          </Link>
        </li>
      );
    }
  });

  return (
    <HeaderWrapper textColor={text} backgroundColor={bgColor}>
      {isDesktop ? (
        <DesktopMenu menu={menu} text={text} location={location} />
      ) : (
        <MobileMenu menu={menu} text={text} location={location} />
      )}
    </HeaderWrapper>
  );
};
