import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

html,
html.wf-loading {
  body {
    opacity: 0;
    will-change: background-color, color, opacity;
    transition: 250ms opacity ease;
  }
}

html.wf-active,
html.wf-inactive {
  body {
    opacity: 1;
  }
}


body {
  font-family: "Nantes", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.05em;

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  @media(max-width: 768px){
    font-size: 16px;
    line-height: 24px;
  }
}



body.mobile-filters-open {
  & header {
    // background-color: transparent;

    & .top-mobile-menu-container{
      & svg {
        & path {
          fill: #163262;
        }
      }
    }

    & ol {
      & li {
        color: #163262;
      }
    }
  }

  &.press {
    & header {
      & ol {
      & li {
        & a {
          color: #163262;
        }
      }
    }
    }
  }
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "Nantes", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}

em {
  font-family: "Nantes", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-style: italic;
}

// br {
//   display: none;
// }



/* Title 1 */
h1{
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0.05em;


  @media(max-width: 768px){
    font-size: 19px;
    line-height: 27px;
    letter-spacing: 0.05em;
  }
}

/* Title 2 */
h2 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 2 */
h4 {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.05em;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    background-color: transparent;
    color: inherit;

    border:0;
    padding:0;
    margin:0;

    cursor:pointer;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, ol, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

strong {
  font-style:normal;
  font-weight:normal;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  ## React Modal
--------------------------------------------------------------*/
.ReactModalPortal{
  position: fixed;

  top:0;
  left:0;
  right:0;
  bottom:0;

  z-index: 100000;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 250ms ease;
  background-color: #fffl
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content{
  width: 100%;
  height: 100%;
}
`;

export default GlobalStyle;
