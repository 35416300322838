import React, { useEffect, useState } from "react";

// Components
import { Search } from "./search";

// Hooks
import { UseSearchData } from "../hooks/useSearchData";

const SearchIndex = ({ location, type, text }) => {
  const data = UseSearchData();

  if (data !== null) {
    return <Search index={data.index} store={data.store} text={text} />;
  } else {
    return null;
  }
};

export default SearchIndex;
