import React from "react";
import styled from "styled-components";

const Icon = styled.svg`
  @media (max-width: 400px) {
    width: 280px;
    height: auto;
  }
`;

export const HorizontalLogo = ({ fill }) => (
  <Icon
    width="311"
    height="15"
    viewBox="0 0 311 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.57086 0.386719H12.373V0.837817H12.1152C11.4063 0.837817 11.0841 1.16003 10.8263 1.67557L7.08868 8.63537V12.373C7.08868 13.1463 7.41089 13.4686 8.18421 13.4686H9.02196V13.8552H3.67323V13.4686H4.51098C5.21985 13.4686 5.54206 13.1463 5.54206 12.373V8.89314L1.35329 1.48224C1.15997 1.09559 0.837753 0.837817 0.25777 0.837817H0V0.386719H4.57542V0.837817H3.73767C3.28657 0.837817 3.09324 1.03114 3.09324 1.35336C3.09324 1.48224 3.15769 1.67557 3.22213 1.80445L6.76647 8.18427L10.1819 1.74001C10.2464 1.54668 10.3108 1.4178 10.3108 1.28891C10.3108 0.966702 10.053 0.773374 9.60194 0.773374H8.57086V0.386719Z"
      fill={fill}
    />
    <path
      d="M17.9141 0.386719H22.7473V0.837817H22.1673C21.4584 0.837817 21.0717 1.16003 21.0717 1.8689V12.373C21.0717 13.1463 21.394 13.4686 22.1673 13.4686H22.7473V13.8552H17.9141V13.4686H18.494C19.2029 13.4686 19.5251 13.1463 19.5251 12.373V1.8689C19.5251 1.16003 19.2029 0.837817 18.494 0.837817H17.9141V0.386719Z"
      fill={fill}
    />
    <path
      d="M37.7016 0.386719H41.9548V0.837817H41.3104C40.6015 0.837817 40.2793 1.16003 40.2793 1.80446V13.9197H39.6993L30.8063 2.06223V12.5019C30.8063 13.2108 31.1285 13.533 31.7729 13.533H32.804V13.9197H28.5508V13.533H29.2597C29.9041 13.533 30.2263 13.2108 30.2263 12.5019V1.48224L30.1618 1.35336C29.9685 1.03114 29.5819 0.837817 29.1308 0.837817H28.6152V0.386719H31.4507L39.6993 11.342V1.80446C39.6993 1.16003 39.3771 0.837817 38.7327 0.837817H37.7016V0.386719Z"
      fill={fill}
    />
    <path
      d="M47.8164 0.837817V0.386719H52.6496V0.837817H51.9407C51.2319 0.837817 50.9741 1.16003 50.9741 1.80446V6.63765L56.4517 1.74001C56.645 1.54668 56.7739 1.4178 56.7739 1.22447C56.7739 0.966702 56.5162 0.837817 56.1295 0.837817H55.0984V0.386719H59.6738V0.837817C58.9005 0.837817 58.1917 0.966702 57.7406 1.35336L52.5852 5.92878L58.385 12.3086C59.0294 13.0819 59.6094 13.533 60.0605 13.5974V13.9841H57.7406C56.9028 13.0175 55.8073 11.7931 54.8406 10.6975L51.5541 6.83098L50.9741 7.34652V12.5664C50.9741 13.2752 51.2963 13.5974 51.9407 13.5974H52.8429V13.9841H47.8809V13.5974H48.5897C49.2341 13.5974 49.5564 13.2752 49.5564 12.5664V1.80446C49.5564 1.16003 49.2341 0.837817 48.5897 0.837817H47.8164Z"
      fill={fill}
    />
    <path
      d="M71.2743 0.386719H71.8543L76.8809 12.7597C77.0742 13.2752 77.4608 13.533 78.0408 13.533H78.4275V13.9841H73.4654V13.5974H74.2387C74.8831 13.5974 75.2053 13.3397 75.2053 12.8886C75.2053 12.7597 75.1409 12.5019 75.0765 12.3086L74.0454 9.60201H68.31L67.2789 12.3086C67.2145 12.5019 67.15 12.6952 67.15 12.8886C67.15 13.3397 67.4722 13.5974 68.1167 13.5974H68.89V13.9841H64.8945V13.533H65.2812C66.1189 13.533 66.3767 13.0819 66.5056 12.6952L71.2743 0.386719ZM73.7876 9.02202L71.1455 2.19111L68.5033 9.02202H73.7876Z"
      fill={fill}
    />
    <path
      d="M102.141 3.99544L101.69 4.05988C101.175 1.54662 99.499 0.644426 97.8235 0.644426C96.0191 0.644426 94.8591 1.73995 94.8591 3.35101C94.8591 5.15541 96.2769 5.73539 97.6302 6.12204L99.3057 6.63758C100.659 7.02424 102.786 7.66866 102.786 10.3108C102.786 12.8241 100.917 14.2418 98.4679 14.2418C96.728 14.2418 95.9547 13.4685 94.9236 13.4685C94.6658 13.4685 94.3436 13.5329 93.9569 13.5974L93.4414 9.40861L93.9569 9.34417C94.7947 12.5663 96.5347 13.6618 98.4679 13.6618C100.53 13.6618 101.561 12.373 101.561 10.8264C101.561 8.82863 99.8212 8.31309 98.7257 7.99088L97.2435 7.53978C95.6969 7.08868 93.6347 6.37981 93.6347 3.73767C93.6347 0.966638 95.8902 0 97.7591 0C99.1768 0 99.9501 0.515541 100.852 0.515541C101.11 0.515541 101.368 0.451098 101.69 0.386655L102.141 3.99544Z"
      fill={fill}
    />
    <path
      d="M117.542 0.386719H122.375V0.837817H121.795C121.086 0.837817 120.699 1.16003 120.699 1.8689V12.373C120.699 13.1463 121.021 13.4686 121.795 13.4686H122.375V13.8552H117.542V13.4686H118.122C118.83 13.4686 119.217 13.1463 119.217 12.373V7.28207H111.935V12.373C111.935 13.1463 112.257 13.4686 113.031 13.4686H113.611V13.8552H108.777V13.4686H109.357C110.066 13.4686 110.388 13.1463 110.388 12.373V1.8689C110.388 1.16003 110.066 0.837817 109.357 0.837817H108.777V0.386719H113.611V0.837817H113.031C112.322 0.837817 111.935 1.16003 111.935 1.8689V6.76653H119.217V1.8689C119.217 1.16003 118.895 0.837817 118.122 0.837817H117.542V0.386719Z"
      fill={fill}
    />
    <path
      d="M141.193 7.15314C141.193 10.6975 138.938 14.2418 134.684 14.2418C130.431 14.2418 128.176 10.6975 128.176 7.15314C128.176 3.60879 130.431 0.0644531 134.684 0.0644531C138.873 0.0644531 141.193 3.60879 141.193 7.15314ZM129.851 7.15314C129.851 11.0197 131.785 13.6618 134.684 13.6618C137.52 13.6618 139.518 11.0841 139.518 7.15314C139.518 3.28658 137.584 0.644436 134.684 0.644436C131.849 0.644436 129.851 3.22214 129.851 7.15314Z"
      fill={fill}
    />
    <path
      d="M156.209 0.386719H160.463V0.837817H159.818C159.109 0.837817 158.787 1.16003 158.787 1.80446V13.9197H158.207L149.314 2.06223V12.5019C149.314 13.2108 149.636 13.533 150.281 13.533H151.312V13.9197H147.059V13.533H147.767C148.412 13.533 148.734 13.2108 148.734 12.5019V1.48224L148.67 1.35336C148.476 1.03114 148.09 0.837817 147.639 0.837817H147.123V0.386719H149.959L158.207 11.342V1.80446C158.207 1.16003 157.885 0.837817 157.241 0.837817H156.209V0.386719Z"
      fill={fill}
    />
    <path
      d="M166.391 0.386719H171.224V0.837817H170.644C169.935 0.837817 169.548 1.16003 169.548 1.8689V12.373C169.548 13.1463 169.871 13.4686 170.644 13.4686H171.224V13.8552H166.391V13.4686H166.971C167.679 13.4686 168.002 13.1463 168.002 12.373V1.8689C168.002 1.16003 167.679 0.837817 166.971 0.837817H166.391V0.386719Z"
      fill={fill}
    />
    <path
      d="M176.895 0.837817V0.386719H182.565C185.272 0.386719 186.625 1.8689 186.625 3.60885C186.625 5.21991 185.465 6.50876 183.597 6.83097C186.11 7.0243 187.27 8.57092 187.27 10.182C187.27 11.9864 185.723 13.9197 182.888 13.9197H176.895V13.533H177.475C178.183 13.533 178.57 13.2108 178.57 12.4375V1.8689C178.57 1.16003 178.248 0.837817 177.475 0.837817H176.895ZM180.052 0.902259V6.70209H181.857C184.048 6.70209 185.143 5.28435 185.143 3.73773C185.143 2.19111 184.048 0.902259 181.985 0.902259H180.052ZM180.052 7.21763V12.5019C180.052 13.1463 180.374 13.4686 180.954 13.4686H182.565C184.692 13.4686 185.659 11.8575 185.659 10.3753C185.659 8.69981 184.499 7.28207 182.501 7.28207H180.052V7.21763Z"
      fill={fill}
    />
    <path
      d="M198.997 0.386719H199.577L204.603 12.7597C204.797 13.2752 205.183 13.533 205.763 13.533H206.15V13.9841H201.188V13.5974H201.961C202.606 13.5974 202.928 13.3397 202.928 12.8886C202.928 12.7597 202.864 12.5019 202.799 12.3086L201.768 9.60201H195.904L194.873 12.3086C194.808 12.5019 194.744 12.6952 194.744 12.8886C194.744 13.3397 195.066 13.5974 195.71 13.5974H196.484V13.9841H192.488V13.533H192.875C193.713 13.533 193.97 13.0819 194.099 12.6952L198.997 0.386719ZM201.446 9.02202L198.804 2.19111L196.161 9.02202H201.446Z"
      fill={fill}
    />
    <path
      d="M211.498 0.837817V0.386719H216.782C219.747 0.386719 221.1 1.93334 221.1 3.60885C221.1 5.54212 219.36 6.95986 216.847 7.08874C218.136 7.41096 219.231 7.9265 220.391 10.182L221.68 12.6308C221.938 13.1463 222.26 13.4686 222.904 13.4686V13.8552H220.584L218.587 9.98866C217.556 8.11982 216.46 7.15319 215.042 7.15319H214.591V12.373C214.591 13.1463 214.913 13.4686 215.687 13.4686H216.267V13.8552H211.434V13.4686H212.014C212.722 13.4686 213.045 13.1463 213.045 12.373V1.8689C213.045 1.16003 212.722 0.837817 212.014 0.837817H211.498ZM214.656 0.902259V6.76653H216.138C218.329 6.76653 219.489 5.41324 219.489 3.80217C219.489 2.25555 218.458 0.966702 216.331 0.966702H214.656V0.902259Z"
      fill={fill}
    />
    <path
      d="M228.512 0.837785V0.386687H236.76C237.469 0.386687 237.856 0.322244 238.307 0.193359L238.694 3.86659L238.307 3.93103C237.856 1.67554 236.438 0.902227 234.827 0.902227H231.605V6.83094H234.376C235.729 6.83094 236.245 5.99319 236.245 4.83322H236.76V9.3442H236.245C236.245 8.18424 235.729 7.34648 234.376 7.34648H231.605V12.2441C231.605 12.8885 231.863 13.4041 232.958 13.4041H235.02C236.76 13.4041 238.049 12.5663 238.629 9.92419L239.145 10.0531L238.694 14.1774C238.307 14.113 237.791 13.9841 237.147 13.9841H228.383V13.5974H228.963C229.672 13.5974 229.994 13.2752 229.994 12.5019V1.86887C229.994 1.16 229.672 0.837785 228.963 0.837785H228.512Z"
      fill={fill}
    />
    <path
      d="M260.542 8.69979L260.284 8.76424C259.833 7.3465 258.866 6.63763 257.771 6.63763C256.16 6.63763 255.258 8.31314 255.258 9.98864C255.258 12.3086 256.611 13.533 258.093 13.533C259.06 13.533 259.962 13.0819 260.606 12.0508L260.8 12.1153C260.22 13.4041 258.931 14.0485 257.706 14.0485C255.967 14.0485 254.227 12.7597 254.227 10.2464C254.227 8.31314 255.451 6.25098 257.9 6.25098C258.802 6.25098 259.189 6.50875 259.64 6.50875C259.704 6.50875 259.962 6.50875 260.284 6.37986L260.542 8.69979Z"
      fill={fill}
    />
    <path
      d="M264.086 6.70212V6.50879H267.308C268.855 6.50879 269.628 7.34654 269.628 8.31318C269.628 9.27982 268.855 9.92424 267.824 10.0531C269.306 10.1176 269.95 11.0198 269.95 11.922C269.95 12.953 269.112 13.9841 267.501 13.9841H264.086V13.7908H264.344C264.795 13.7908 264.988 13.5975 264.988 13.1464V7.41098C264.988 6.95989 264.795 6.76656 264.344 6.76656H264.086V6.70212ZM266.019 6.76656V9.98869H266.793C268.017 9.98869 268.597 9.21538 268.597 8.37762C268.597 7.47543 268.017 6.831 266.857 6.831H266.019V6.76656ZM266.019 10.2465V13.1464C266.019 13.4686 266.148 13.6619 266.535 13.6619H267.179C268.275 13.6619 268.79 12.8242 268.79 11.922C268.79 10.9553 268.146 10.2465 267.115 10.2465H266.019Z"
      fill={fill}
    />
    <path
      d="M273.301 6.70161V6.50828H278.005C278.392 6.50828 278.585 6.44384 278.843 6.37939L279.101 8.506L278.843 8.57044C278.521 7.28159 277.618 6.83049 276.781 6.83049H275.234V10.1171H276.652C277.361 10.1171 277.747 9.66596 277.747 8.9571H278.005V11.5348H277.747C277.747 10.8259 277.425 10.3748 276.652 10.3748H275.234V13.0814C275.234 13.4036 275.363 13.7258 276.007 13.7258H276.91C277.812 13.7258 278.714 13.2747 279.101 11.7281L279.358 11.7926L279.101 14.1769C278.907 14.1125 278.585 14.0481 278.263 14.0481H273.301V13.8547H273.559C274.01 13.8547 274.203 13.6614 274.203 13.2103V7.47492C274.203 7.02382 274.01 6.83049 273.559 6.83049H273.301V6.70161Z"
      fill={fill}
    />
    <path
      d="M294.568 6.70212V6.50879H297.662C299.273 6.50879 300.046 7.34654 300.046 8.31318C300.046 9.47315 298.886 10.1176 297.662 10.2465C298.37 10.4398 299.015 10.6976 299.659 11.9864L300.304 13.3397C300.433 13.6619 300.626 13.7908 300.948 13.7908V13.9841H299.466L298.37 11.8575C297.855 10.8909 297.404 10.3109 296.631 10.3109H296.437V13.0819C296.437 13.533 296.631 13.7264 297.082 13.7264H297.339V13.9197H294.504V13.7264H294.762C295.213 13.7264 295.406 13.533 295.406 13.0819V7.34654C295.406 6.89544 295.213 6.70212 294.762 6.70212H294.568ZM296.502 6.76656V9.98869H297.146C298.37 9.98869 298.95 9.27982 298.95 8.37762C298.95 7.53987 298.435 6.76656 297.211 6.76656H296.502Z"
      fill={fill}
    />
    <path
      d="M307.134 6.50879H307.456L310.163 13.3397C310.292 13.5975 310.485 13.7264 310.807 13.7264H311.001V13.9841H308.036V13.7908H308.423C308.81 13.7908 309.003 13.6619 309.003 13.4041C309.003 13.3397 309.003 13.2108 308.939 13.0819L308.359 11.6642H305.459L304.943 13.0819C304.879 13.2108 304.879 13.2753 304.879 13.4041C304.879 13.6619 305.072 13.8552 305.394 13.8552H305.781V14.0486H303.59V13.7908H303.783C304.299 13.7908 304.428 13.533 304.492 13.3397L307.134 6.50879ZM308.294 11.2131L306.941 7.66876L305.588 11.2131H308.294Z"
      fill={fill}
    />
  </Icon>
);
