import React from "react";
import styled from "styled-components";

const Icon = styled.svg``;

export const Open = ({ fill }) => (
  <Icon
    width="13"
    height="14"
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.93 6.9H12.6V5.73H6.93V0H5.7V5.73H0V6.9H5.7V13.17H6.93V6.9Z"
      fill={fill}
    />
  </Icon>
);

export const Close = ({ fill }) => (
  <Icon
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.37094 5.79L12.2909 0.869999L11.4509 0L6.50094 4.95L1.55094 0L0.710938 0.869999L5.66094 5.79L0.710938 10.74L1.58094 11.58L6.50094 6.66L11.4209 11.58L12.2909 10.74L7.37094 5.79Z"
      fill="#163262"
    />
  </Icon>
);
