exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bibliography-js": () => import("./../../../src/pages/bibliography.js" /* webpackChunkName: "component---src-pages-bibliography-js" */),
  "component---src-pages-biography-js": () => import("./../../../src/pages/biography.js" /* webpackChunkName: "component---src-pages-biography-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cv-js": () => import("./../../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-splash-js": () => import("./../../../src/pages/splash.js" /* webpackChunkName: "component---src-pages-splash-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-templates-artwork-js": () => import("./../../../src/templates/artwork.js" /* webpackChunkName: "component---src-templates-artwork-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */)
}

