import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useClickAway } from "react-use";

// Components
import SearchIndex from "../search/search-index";

// Icons
import { HorizontalLogo } from "../icons/horizontal-logo";
import { Open, Close } from "../icons/menu-toggle";

const MobileMenuContainer = styled.div`
  padding: 18px 20px;

  &.is-open {
    background-color: #5694fd;

    & .top-mobile-menu-container {
      & svg {
        & path {
          fill: #163262;
        }
      }
    }
  }

  & .top-mobile-menu-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;

    & a {
      display: block;
      height: 15px;
      line-height: 1;
    }

    & .menu-toggle {
      height: 14px;
      line-height: 1;
    }
  }

  & .mobile-menu-container {
    padding: 90px 0 0 0;

    @media (max-width: 768px) {
      padding: 73px 0 0 0;
    }

    & ol {
      margin: 0 0 32px 0;

      & li {
        color: #163262;

        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.05em;

        padding: 0 0 7px 0;
      }
    }

    & button {
      color: #163262;

      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.05em;

      & svg {
        margin: 0 10px 0 0;

        & path {
          stroke: #163262;
        }
      }
    }
  }

  & .search-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    height: 21px;
    font-size: 14px;

    & svg {
      margin: 0 10px 0 0;
      width: 12px;
      height: 12px;

      & path {
        stroke: #163262;
      }
    }

    & button {
      color: #163262;
    }
  }
`;

export const MobileMenu = ({ menu, text, location }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  useClickAway(menuRef, () => {
    setIsMenuOpen(false);
  });

  return (
    <MobileMenuContainer
      className={isMenuOpen ? `is-open` : `is-closed`}
      ref={menuRef}
    >
      <div className="top-mobile-menu-container">
        <Link to={`/`}>
          <HorizontalLogo fill={text} />
        </Link>

        <button
          className="menu-toggle"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <Close fill={text} /> : <Open fill={text} />}
        </button>
      </div>

      {isMenuOpen && (
        <>
          <nav className="mobile-menu-container">
            <ol>{menu}</ol>
          </nav>
          <div className="search-container">
            <SearchIndex type={"mobile"} text={text} location={location} />
          </div>
        </>
      )}
    </MobileMenuContainer>
  );
};
