import React from "react";

//www.joshwcomeau.com/react/the-perils-of-rehydration/
export function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted;
}
