import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 0 15px 0 40px;

  font-size: 14px;
  line-height: 26px;
  letter-spacing: 0.05em;

  & ol {
    padding: 0;
    margin: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  & li {
    padding-right: 20px;
    &:last-of-type {
      padding-right: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 0 20px;
  }
`;

export const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      prismicMenus {
        data {
          text {
            html
          }
          footer {
            page {
              document {
                ... on PrismicTermsAndConditions {
                  id
                  url
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const links = data.prismicMenus.data.footer.map((link, index) => (
    <li key={`single_footer_link_${index}`}>
      <p>
        <Link to={link.page.document.url}>
          {link.page.document.data.title.text}
        </Link>
      </p>
    </li>
  ));

  return (
    <FooterWrapper>
      <ol>
        <li>
          <div
            dangerouslySetInnerHTML={{
              __html: data.prismicMenus.data.text.html,
            }}
          />
        </li>

        {links}
      </ol>
    </FooterWrapper>
  );
};
