import React from "react";
import { Link } from "gatsby";
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies";
import CookieConsent from "react-cookie-consent";
import styled from "styled-components";

const CookieContainer = styled.div`
  z-index: 100;

  & .privacy-policy-button {
    cursor: pointer;

    margin: 0 25px 0 55px;

    @media (max-width: 940px) {
      margin: 0;
    }
  }

  & .cookie-container {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 100;
    background-color: #fafaf5;

    padding: 10px 40px;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;

    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    @media (max-width: 940px) {
      max-width: 270px;
    }

    @media (max-width: 600px) {
      max-width: 148px;
      padding: 15px;

      font-size: 8px;
      line-height: 12px;
    }
  }

  & .content-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & p {
      margin: 0;
    }
  }

  & .cookie-text {
    & p {
      margin: 0;
    }

    @media (max-width: 940px) {
      margin: 0 0 1em 0;
    }
  }

  & .cookie-button {
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0.05em;

    border: 0;
    margin: 0;
    padding: 0;

    cursor: pointer;

    @media (max-width: 600px) {
      font-size: 8px;
      line-height: 12px;
    }
  }

  & .button-container {
    @media (max-width: 940px) {
      position: absolute;
      right: 34px;
      bottom: 34px;
    }

    @media (max-width: 600px) {
      right: 15px;
      bottom: 15px;
    }
  }
`;

export const CookiesComponent = ({ location }) => {
  return (
    <CookieContainer data-nosnippet>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="yinka-shonibare-gdpr-google-analytics"
        containerClasses="cookie-container"
        contentClasses="content-container"
        buttonWrapperClasses="button-container"
        buttonClasses="cookie-button"
        disableStyles={true}
        onAccept={() => {
          initializeAndTrack(location);
        }}
        expires={150}
      >
        <div className="cookie-text">
          <p>This website uses cookies to enhance the user experience</p>
        </div>

        <Link to={`/terms-and-conditions/`} className="privacy-policy-button">
          Learn more
        </Link>
      </CookieConsent>
    </CookieContainer>
  );
};
