import React, { useState, useEffect } from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";
import { useSessionStorage } from "react-use";
import isbot from "isbot";

// Context
import { PageBackgroundColor } from "../context/page-background-color";

// Hooks
import { useMediaQuery } from "../hooks/useMediaQuery";
import { useHasMounted } from "../hooks/useHasMounted";

// Styles
import GlobalStyles from "../styles/globalStyles";

// Components
import { App } from "./app";
import { DefaultSEO } from "./default-seo";
import { Splash } from "./splash";
import { CookiesComponent } from "./cookies";

const Container = styled.div`
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;
`;

const Layout = ({ children }) => {
  const [pageBackgroundColor, setPageBackgroundColor] = useState({
    text: `#000`,
    background: `#ffffff`,
  });

  useEffect(() => {
    document.body.style.backgroundColor = pageBackgroundColor.background;
    document.body.style.color = pageBackgroundColor.text;
  }, [pageBackgroundColor]);

  // Media Query
  let isDesktop = useMediaQuery("(min-width: 769px)");

  // Splash
  const [hideSplash, setHideSplash] = useSessionStorage("hideSplash", false);

  // Check if a bot
  const [isUserBot, setIsUserBot] = useState(false);
  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsUserBot(isbot(navigator.userAgent));
    }
  }, []);

  useEffect(() => {
    console.log(
      "%cDevelopment: Kieran Startup: www.kieranstartup.co.uk",
      "color:red; font-family:Helvetica Neue;  font-size: 20px"
    );
  }, []);

  // Rehydration stuff
  const hasMounted = useHasMounted();

  if (!hasMounted) {
    return null;
  }

  return (
    <PageBackgroundColor.Provider
      value={[pageBackgroundColor, setPageBackgroundColor]}
    >
      <Location>
        {({ location }) => {
          return (
            <>
              <Container location={location.pathname}>
                <Normalize />
                <GlobalStyles />
                <DefaultSEO />
                <App
                  location={location.pathname}
                  children={children}
                  text={pageBackgroundColor.text}
                  background={pageBackgroundColor.background}
                  isDesktop={isDesktop}
                  hideSplash={hideSplash}
                  setHideSplash={setHideSplash}
                />
                <CookiesComponent location={location} />
              </Container>

              {!isUserBot && location.pathname === `/` && (
                <>
                  {!hideSplash && (
                    <Splash
                      isDesktop={isDesktop}
                      onClick={() => {
                        setHideSplash(true);
                      }}
                    />
                  )}
                </>
              )}
            </>
          );
        }}
      </Location>
    </PageBackgroundColor.Provider>
  );
};

export default Layout;
